body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.pointer {
    cursor: pointer;
}

/* components/Common/ValidationInput */
.glyphicon.glyphicon-remove.form-control-feedback {
    right: 15px;
}

/* containers/Home */
.home-container-link-container {
    margin-bottom: 20px;
}

/* components/PaymentForm */
.payment-form-component-save-account-link {
    font-size: 11px;
}

/* components/Info */
.x-button {
    cursor: pointer;
    margin-left: 45px;
}

.third-width {
    width: 33.33333%;
}

.ellipsis {
    overflow-y: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.hover-item:hover {
    box-shadow: 5px 5px 5px #337ab7;
}

.title-help {
    font-size: 13px;
    font-style: italic;
}

/* containers/messaging-admin/UpdateMessagingModal */

.update-messaging-modal-form textarea {
    height: 400px;
}

/* containers/e-sign-admin/ESign */

.delete-template {
    margin-right: -21px;
    font-size: 1.3rem;
}

.table-responsive .table tbody tr td {
    vertical-align: middle;
}

.glyphicon {
    font-family: 'Glyphicons Halflings' !important;
}

a:not(.btn),
.breadcrumb a,
.react-bootstrap-table a, 
.btn-link {
    color: #000a2d !important;
}

.alert-success {
    border-color: transparent; 
}

.label-primary {
   background-color: #2c3142;
}

.hover-item:hover {
    -webkit-box-shadow: 5px 5px 5px #2c3142;
    box-shadow: 5px 5px 5px #2c3142;
}

.list-group-item-info {
    background-color: #C2C4C9;
}

.modal-body .list-group-item.active, 
.modal-body .list-group-item.active:focus, 
.modal-body .list-group-item.active:hover {
    background-color: #2c3142;
    border-color: #12151C;
}

.modal-body .list-group-item.active>.badge, 
.modal-body .nav-pills>.active>a>.badge {
    color: #2c3142;
    background-color: #fff;
}

input[type="checkbox"]:disabled {
    filter: none;
    -webkit-filter: none;
}

.mr-2 {
    margin-right: 0.4rem !important;
}

.pl-0 {
    padding-left: 0 !important;
}

html {
    min-height: 100%;
}

body {
    position: relative;
    height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: stretch;
}

#root {
    flex: 1;
    position: relative;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-flow: column;
    align-items: stretch;
}

.app-container-wrapper {
    flex: 1;
}

footer#footer {
    position: static;
}