.Tasks-task {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px dotted lightgray;
    font-weight: normal;
    font-style: normal;
}

.Tasks-task-inProgress {
    font-style: italic;
}

.Tasks-task-finished {
    font-style: normal;
}

.Tasks-task-hasNotBeenRead {
    font-weight: bold;
}

.Tasks-task-hasBeenRead {
}

.Tasks-task-finished-failed {
    color: #a94442
}

.Tasks-task-main {
    cursor: pointer;
    flex: 1;
    display: flex;
}
.Tasks-task-title {
    flex: 1;
}
.Tasks-task-created {
    opacity: .4;
    padding-left: 16px;
}

.Tasks-task-delete {
    opacity: 0;
}
.Tasks-task-delete:hover,
.Tasks-task-delete:focus-within,
.Tasks-task-hasBeenRead .Tasks-task-delete
{
    opacity: 1;
}

.Tasks-hasBeenReadToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: -8px;
    cursor: pointer;
}

.Tasks-hasBeenReadToggle-circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: 1px solid darkgrey;
    opacity: 0;
    background: white;
}
.Tasks-task-finished-completed .Tasks-hasBeenReadToggle-circle {
    border: 1px solid green;
}
.Tasks-task-finished-failed .Tasks-hasBeenReadToggle-circle {
    border: 1px solid #a94442;
}
.Tasks-task-finished-failed .Tasks-hasBeenReadToggle-circle {
    border: 1px solid #a94442;
}
.Tasks-task-hasNotBeenRead .Tasks-hasBeenReadToggle-circle {
    opacity: .6;
    background: darkgray;
}

.Tasks-task-finished-completed.Tasks-task-hasNotBeenRead .Tasks-hasBeenReadToggle-circle {
    opacity: .6;
    background: green;
}

.Tasks-task-finished-failed.Tasks-task-hasNotBeenRead .Tasks-hasBeenReadToggle-circle {
    opacity: .6;
    background: #a94442;
}

.Tasks-task-hasNotBeenRead:hover .Tasks-hasBeenReadToggle-circle {
    opacity: 1;
    background: white;
}

.Tasks-task-hasBeenRead:hover .Tasks-hasBeenReadToggle-circle {
    opacity: 1;
}
