a, button {
    cursor: pointer;
}

.notifications {
    margin: 15px 0;
}

.info {
    margin-top: 20px;
}

.form-list-gap {
    margin-top: 30px;
}

tr {
    cursor: pointer;
}

tr.selected {
    background-color: #f5f5f5;
}

/* NOTE - This could be better managed in SASS, but we do not have processing for that yet */
.margin-top-40px {
    margin-top: 40px;
}

.margin-bottom-40px {
    margin-bottom: 40px;
}

.margin-top-20px {
    margin-top: 20px;
}

.margin-bottom-20px {
    margin-bottom: 20px;
}

.margin-top-10px {
    margin-top: 10px;
}

.margin-bottom-10px {
    margin-bottom: 10px;
}
